import { Spin, Tooltip } from 'antd';
import { useCheckConnectedWallet } from 'hooks/player/useCheckConnectedWallet';
import { useGetPlayer } from 'hooks/player/useGetPlayer';
import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { linkWallet } from 'services/api';
import { LocalhostStorage } from 'utils/sessionStorage';
import { useAccount, useSignMessage } from 'wagmi';
import {
  ButtonWrapper,
  Description,
  LinkButton,
  StyledModal,
  SubmitButton,
} from './styled';
import useWagmiConnectors from 'hooks/useWagmiConnectors';

export const LinkWalletModal = ({
  onRefresh,
  refresh,
}: { onRefresh?: () => void; refresh?: boolean } = {}) => {
  const { address: account, isConnected: active } = useAccount();
  const { onDisconnect: disconnect } = useWagmiConnectors();

  const [loading, setLoading] = useState<boolean>(false);
  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { playerInfo } = useGetPlayer(null, refresh);

  const [params, setParams] = useState<{
    walletAddress?: string;
  }>({
    walletAddress: account,
  });
  const {
    isConnected,
    loading: checkLoading,
    setIsConnected,
  } = useCheckConnectedWallet(params, params);

  const {
    signMessage: signMessageWagmi,
    error,
    data: signMessageData,
    variables,
    reset,
  } = useSignMessage();

  const onDisconnect = () => {
    setIsOpen(false);
    disconnect();
  };

  const handleLinkWalletToPlayer = useCallback(async () => {
    const typeOfConnector = LocalhostStorage.get('typeOfConnector');

    if (isConfirmed) {
      if (loading && !signMessageData && !error) {
        return;
      }
      setLoading(true);
      if (typeOfConnector === 'Metamask') {
        if (error) {
          setLoading(false);
          setIsConfirmed(false);
          toast.error('User denied message signature.');
          reset();
          onDisconnect();
          return;
        }

        if (signMessageData) {
          const linkWalletRes = await linkWallet({
            walletAddress: account,
            signature: signMessageData,
            message: String(variables?.message),
          });
          setLoading(false);
          setIsConfirmed(false);
          if (linkWalletRes?.success) {
            toast.success('Connect wallet to player successfully');
            if (onRefresh) {
              onRefresh();
            }
          } else {
            toast.error(
              linkWalletRes?.message || 'Connect wallet to player failed',
            );
          }
        } else {
          await signMessageWagmi({
            account: account,
            message: `If you click the 'sign' button, you agree to authorize us to make this transaction.
          Your Wallet Address: ${account}
          Timestamp: ${Date.now()}
         `,
          });
        }
      }
    }
  }, [isConfirmed, error, signMessageData]);

  useEffect(() => {
    if (active && account && playerInfo && !playerInfo.walletAddress) {
      setParams({ walletAddress: account });
    }
  }, [active, account, playerInfo]);

  useEffect(() => {
    if (
      account &&
      active &&
      playerInfo &&
      !playerInfo.walletAddress &&
      !checkLoading
    ) {
      if (isConnected === true) {
        toast.error(
          'The wallet address has been already connected to other player',
        );
        onDisconnect();
        setIsConnected(undefined);
        return;
      }
      if (isConnected === false) {
        setIsOpen(true);
        setIsConnected(undefined);
      }
    }
  }, [active, account, playerInfo, isConnected, checkLoading]);

  useEffect(() => {
    handleLinkWalletToPlayer();
  }, [handleLinkWalletToPlayer]);

  return (
    <>
      <Spin spinning={loading}>
        <StyledModal isOpen={isOpen} width="500px" onCancel={onDisconnect}>
          <div>
            <Description>
              Your account is not connected to any wallets. If you want to
              connect your account to the wallet address{' '}
              <Tooltip title={account}>
                <b>
                  <span>{`${account?.substring(0, 6)}...${account?.substring(
                    38,
                  )}`}</span>
                </b>
              </Tooltip>
              , please click on Confirm button and sign message on the next step
            </Description>

            <ButtonWrapper>
              <LinkButton height="40px" width="150px" onClick={onDisconnect}>
                Disconnect
              </LinkButton>
              <SubmitButton
                height="40px"
                width="150px"
                onClick={() => {
                  setIsConfirmed(true);
                  setIsOpen(false);
                }}
              >
                Confirm
              </SubmitButton>
            </ButtonWrapper>
          </div>
        </StyledModal>
      </Spin>
    </>
  );
};
