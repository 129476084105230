import {
  DeleteOutlined,
  UnlockOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { Row } from 'antd';
import defaultAvatarImg from 'assets/images/default-user-profile.png';
import { useState } from 'react';

import {
  ActionWrapper,
  AdminPanelTable,
  BodyContentWrapper,
  StyledPagination,
  TableContentWrapper,
  UserCellWrapper,
} from './styled';
import { useGetSecurityFlagList } from 'hooks/security-flag/useGetSecurityFlagList';
import { updateFlagStatus } from 'services/api';
import { toast } from 'react-toastify';

export const SecurityFlagTable = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const defaultPageSize = 50;
  const [params, setParams] = useState({
    pageSize: defaultPageSize,
    pageIndex: 1,
    sortField: 'revenue',
    sortDirection: 'desc',
  });
  const [refresh, setRefresh] = useState<boolean>(false);

  const {
    loading: whitelistLoading,
    securityFlagList,
    total,
  } = useGetSecurityFlagList(params, refresh);

  const columns = [
    {
      title: '',
      dataIndex: 'key',
      key: 'key',
      render: value => params.pageSize * (currentPage - 1) + value + 1,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (username, record) => (
        <UserCellWrapper>
          <img src={record?.avatarURL || defaultAvatarImg} />
          <div>{username}</div>
        </UserCellWrapper>
      ),
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      sorter: true,
      render: time => <div>{new Date(time).toLocaleString()}</div>,
    },
    {
      title: 'Game',
      dataIndex: 'game',
      key: 'game',
      sorter: true,
      render: game => <div>{game}</div>,
    },
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      sorter: true,
      render: level => <div>{level}</div>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record, index) => (
        <ActionWrapper>
          <DeleteOutlined
            disabled={false}
            style={{ fontSize: '20px', cursor: 'pointer', color: '#ff525b' }}
          />

          {record.status ? (
            <LockOutlined
              style={{ fontSize: '20px', cursor: 'pointer', color: '#fff' }}
              onClick={() => updateStatus(record.username, record.status)}
            />
          ) : (
            <UnlockOutlined
              style={{ fontSize: '20px', cursor: 'pointer', color: '#fff' }}
              onClick={() => updateStatus(record.username, record.status)}
            />
          )}
        </ActionWrapper>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setParams({
      ...params,
      sortField: sorter.field && sorter.order ? sorter.field : undefined,
      sortDirection: sorter.order
        ? sorter.order === 'descend'
          ? 'desc'
          : 'asc'
        : undefined,
    });
  };

  const updateStatus = async (userId: string, status: boolean) => {
    try {
      const response = await updateFlagStatus(userId, {
        status: !status,
      });
      if (response?.success) {
        toast.success('Update status success!!');
        setRefresh(!refresh);
      } else toast.error(response?.message);
    } catch (error) {
      toast.error('An error occurred while updating the status.');
      console.log(error);
    }
  };

  return (
    <TableContentWrapper>
      <BodyContentWrapper>
        <AdminPanelTable
          dataSource={securityFlagList?.map((item, key) => ({ ...item, key }))}
          columns={columns}
          pagination={false}
          loading={whitelistLoading}
          onChange={handleTableChange}
        />
        <Row justify="center" className="mb-5">
          {total > defaultPageSize + 1 && (
            <StyledPagination
              className="mt-4"
              pageSize={defaultPageSize + 1}
              current={currentPage}
              onChange={page => {
                setCurrentPage(page);
                setParams({
                  ...params,
                  pageIndex: page,
                });
              }}
              total={total}
              showSizeChanger={false}
            />
          )}
        </Row>
      </BodyContentWrapper>
    </TableContentWrapper>
  );
};
