export enum AdminPanelTabs {
  platformConfig = 'platformConfig',
  admin = 'admin',
  gameProvider = 'gameProvider',
  affiliate = 'affiliate',
  tester = 'tester',
  supportedToken = 'supportedToken',
  gamerData = 'gamer-data',
  gameRevenue = 'game-revenue',
  securityFlag = 'security-flag',
}
