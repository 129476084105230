import HomepageTemplate from 'template/Homepage';
import {
  CustomSlider,
  InputWrapper,
  LargeLabel,
  RowWrapper,
  SelectStyled,
  StakeWrapper,
  StakingActionContainer,
  StakingButton,
  StakingInput,
  StakingPageTemplate,
  AmountWrapper,
  FAQSContainer,
  FAQSContent,
  GradientBottom,
  GradientTop,
  StakingBody,
  InputRow,
  DeadlineWrapper,
  WalletNotConnectedWrapper,
  StakingHeader,
  CustomedDivider,
  TabWrapper,
  TabsContainer,
  GradientMobile,
  AnswerQuestionContainer,
} from './styled';
import { StakingMonths, StakingTabs } from 'constants/enum/Staking';
import { StakingCarousel } from 'components/Staking/StakingCarousel';
import gradient from 'assets/images/staking/BG-gradient.svg';
import gradientMobile from 'assets/images/staking/bg-gradient-mobile.svg';
import { StakingFooter } from 'components/Staking/StakingFooter';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from 'redux/store';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { useGetStakeInfo } from 'hooks/staking/useGetStakeInfo';
import { useStakeCallback } from 'hooks/staking/useStakeCallback';
import { toast } from 'react-toastify';
import { useGetStakingTokenInfo } from 'hooks/erc20/useGetStakingTokenInfo';
import useStakingContractInfo from 'hooks/contract/useStakingContractInfo';
import useERC20Callback from 'hooks/erc20/useERC20Callback';
import { useGetStakeUser } from 'hooks/staking/useGetStakeUser';
import { BigNumber } from 'ethers';
import { postContractAction } from 'services/api/contract_action';
import { EventType } from 'constants/enum/EventType';
import { LocalhostStorage } from 'utils/sessionStorage';
import { formatDate } from 'utils/formatDate';
import { ReactComponent as WalletIcon } from 'assets/icons/wallet.svg';
import { ReactComponent as HeaderIcon } from 'assets/images/staking/header-icon.svg';
import { useGetTermDeadline } from 'hooks/staking/useGetTermDeadline';
import { ContractName } from 'constants/enum/ContractName';
import { HowToEarnUSDTMobile } from 'components/Staking/HowToEarnUSDTMobile';
import { useAccount, useSwitchChain } from 'wagmi';
import {
  MAINNET_CHAIN_ID,
  NET_TYPE,
  SEPOLIA_CHAIN_ID,
  SUPPORTED_NETWORKS,
} from 'utils/wallets/connector';

const options = [
  {
    label: StakingMonths.M_24,
    value: 50,
    term: 24,
  },
  {
    label: StakingMonths.M_12,
    value: 30,
    term: 12,
  },
  {
    label: StakingMonths.M_6,
    value: 15,
    term: 6,
  },
];

export const StakingPage = () => {
  const [amount, setAmount] = useState(0);
  const [apyInput, setApyInput] = useState(0);
  const [reward, setReward] = useState(0);
  const { stake, withdrawStake } = useStakeCallback();
  const [loading, setLoading] = useState<boolean>(false);
  const [isUnstake, setIsUnstake] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [refreshStakeInfo, setRefreshStakeInfo] = useState<boolean>(false);
  const { address: account, chainId } = useAccount();
  const [activeTab, setActivetab] = useState(StakingTabs.STAKE);

  const { tokenInfo, loading: tokenInfoLoading } = useGetStakingTokenInfo(
    process.env.REACT_APP_STAKING_TOKEN,
    refresh,
  );

  const contractInfo = useStakingContractInfo();

  const { stakeData } = useGetStakeInfo(refreshStakeInfo);

  const { stakeInfo } = useGetStakeUser(account, refreshStakeInfo);

  const { approve } = useERC20Callback(process.env.REACT_APP_STAKING_TOKEN);

  const { deadline } = useGetTermDeadline(
    stakeInfo?.startTime,
    stakeInfo?.term,
  );

  const { switchChain } = useSwitchChain();

  const handleChange = (value: number) => {
    setApyInput(value);
    setReward((value * amount) / 100 / 10);
  };

  const handleChangeSlider = (value: number) => {
    setAmount(value);
    setReward((value * apyInput) / 100 / 10);
  };

  const isShowSwitchBtn = useMemo(() => {
    if (chainId === MAINNET_CHAIN_ID || chainId === SEPOLIA_CHAIN_ID)
      return false;
    return true;
  }, [chainId]);

  const networkType = useMemo(() => {
    if (!chainId) return;
    const network = Object.values(SUPPORTED_NETWORKS).find(
      network => network.chainId === chainId,
    );
    if (network) {
      return network.netType;
    } else {
      return;
    }
  }, [chainId]);

  const handleSwitchChain = () => {
    if (networkType && networkType === NET_TYPE.TEST_NET) {
      switchChain(
        { chainId: SEPOLIA_CHAIN_ID },
        {
          onError: error => {
            console.log(error.message);
          },
          onSuccess: () => {
            LocalhostStorage.set('chainId', SEPOLIA_CHAIN_ID);
            toast.success('Successfully switched to the Sepolia network.');
          },
        },
      );
      return;
    }

    if (networkType && networkType === NET_TYPE.MAIN_NET) {
      switchChain(
        { chainId: MAINNET_CHAIN_ID },
        {
          onError: error => {
            console.log(error.message);
          },
          onSuccess: () => {
            LocalhostStorage.set('chainId', MAINNET_CHAIN_ID);
            toast.success('Successfully switched to the Ethereum network.');
          },
        },
      );
    }
  };

  const handleStake = async () => {
    try {
      setLoading(true);
      const config = {
        amount: parseUnits(amount.toString(), tokenInfo.decimals),
        term: options.find(i => i.value === apyInput).term,
      };
      const res = await stake(config);
      if (res) {
        await postContractAction({
          eventName: EventType.Staked,
          transactionHash: res.transactionHash,
          contractName: ContractName.BIGA_STAKING_CONTRACT,
          chainId: chainId,
        });
        toast.success('Stake success!!!');
        setRefreshStakeInfo(!refreshStakeInfo);
        setRefresh(!refresh);
      }
    } catch (e) {
      toast.error(e.reason);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleUnstake = async () => {
    try {
      setLoading(true);
      const res = await withdrawStake();
      if (res) {
        await postContractAction({
          eventName: EventType.WithdrawnStake,
          transactionHash: res.transactionHash,
          contractName: ContractName.BIGA_STAKING_CONTRACT,
          chainId: chainId,
        });
        toast.success('Unstake success!!!');
        setRefreshStakeInfo(!refreshStakeInfo);
        setRefresh(!refresh);
      }
    } catch (e) {
      toast.error(e.reason);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async () => {
    try {
      setLoading(true);
      const res = await approve(contractInfo.StakingContractAddress);
      if (res) {
        toast.success('Approve success!!!');
        setRefresh(!refresh);
      }
    } catch (e) {
      toast.error(e.reason);
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const deadlineDate = useMemo(() => {
    if (!deadline) return;
    const timestamp = BigNumber.from(deadline).toNumber();
    const date = new Date(timestamp * 1000);
    return date;
  }, [deadline]);

  const isDeadline = useMemo(() => {
    if (!deadlineDate) return false;
    const currentDate = new Date();
    return deadlineDate < currentDate;
  }, [deadlineDate]);

  useEffect(() => {
    if (
      stakeInfo &&
      tokenInfo &&
      Number(formatUnits(stakeInfo.amount, tokenInfo.decimals)) > 0
    ) {
      setIsUnstake(true);
      const apy = options.find(i => i.term === stakeInfo.term)?.value || 0;
      const tokenAmount = Number(
        formatUnits(stakeInfo.amount, tokenInfo.decimals),
      );
      setAmount(tokenAmount);
      setApyInput(apy);
      setReward((tokenAmount * apy) / 100 / 10);
    } else {
      setIsUnstake(false);
      setAmount(0);
      setReward(0);
      setApyInput(options[0].value);
    }
  }, [stakeInfo, tokenInfo]);

  const isDisabledInput = useMemo(() => {
    if (
      isUnstake ||
      tokenInfoLoading ||
      Number(tokenInfo?.allowance) < amount ||
      Number(tokenInfo?.allowance) === 0
    )
      return true;

    return false;
  }, [tokenInfo, isUnstake, amount, tokenInfoLoading]);

  const isDisableApproveBtn = useMemo(() => {
    if (
      (!!account &&
        ((Number(tokenInfo?.balanceOf) >= amount &&
          Number(tokenInfo?.allowance) < amount) ||
          Number(tokenInfo?.allowance) === 0)) ||
      tokenInfoLoading
    )
      return true;

    return false;
  }, [tokenInfo, amount, account, tokenInfoLoading]);

  return (
    <HomepageTemplate>
      <StakingPageTemplate>
        <StakingHeader>
          <HeaderIcon />
          Staking
        </StakingHeader>
        <StakingBody>
          <RowWrapper className="banner-tabs-wrapper">
            <StakeWrapper>
              <StakingCarousel />
            </StakeWrapper>
            <TabsContainer>
              <TabWrapper
                onClick={() => setActivetab(StakingTabs.STAKE)}
                className={activeTab === StakingTabs.STAKE ? 'active-tab' : ''}
              >
                {StakingTabs.STAKE}
              </TabWrapper>
              <CustomedDivider type="vertical" className="tab-divider" />
              <TabWrapper
                onClick={() => setActivetab(StakingTabs.HOW_TO_EARN_USDT)}
                className={
                  activeTab === StakingTabs.HOW_TO_EARN_USDT ? 'active-tab' : ''
                }
              >
                {StakingTabs.HOW_TO_EARN_USDT}
              </TabWrapper>
            </TabsContainer>
            <StakeWrapper>
              {activeTab === StakingTabs.HOW_TO_EARN_USDT && (
                <HowToEarnUSDTMobile />
              )}
              <StakingActionContainer
                className={`staking-form ${
                  activeTab !== StakingTabs.STAKE ? 'hidden' : ''
                }`}
              >
                {account ? (
                  <>
                    <InputRow>
                      <InputWrapper>
                        <LargeLabel>APY</LargeLabel>
                        <StakingInput value={apyInput + '%'} disabled={true} />
                      </InputWrapper>
                      <InputWrapper>
                        <LargeLabel>Reward</LargeLabel>
                        <StakingInput
                          disabled={true}
                          value={
                            reward.toLocaleString(undefined, {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                            }) + ' USDT'
                          }
                        />
                      </InputWrapper>
                    </InputRow>
                    <InputRow>
                      <label>Choose your term</label>
                      <SelectStyled
                        options={options}
                        onChange={handleChange}
                        disabled={isDisabledInput}
                        value={apyInput ? apyInput : null}
                      />
                    </InputRow>
                    <InputRow>
                      <label htmlFor="">Select amount</label>
                      <CustomSlider
                        disabled={isDisabledInput}
                        defaultValue={0}
                        onChange={handleChangeSlider}
                        value={amount}
                        min={0}
                        max={Number(tokenInfo?.balanceOf) || 0}
                      />
                    </InputRow>
                    <InputRow className="deadline-biga-wrapper">
                      {!!account && isUnstake && !!deadlineDate && (
                        <DeadlineWrapper>
                          <span>Release date: {formatDate(deadlineDate)}</span>
                        </DeadlineWrapper>
                      )}
                      <AmountWrapper>
                        <span>
                          {amount.toLocaleString(undefined, {
                            maximumFractionDigits: 2,
                          })}{' '}
                          BIGA
                        </span>
                      </AmountWrapper>
                    </InputRow>
                    {isShowSwitchBtn ? (
                      <StakingButton onClick={handleSwitchChain}>
                        Switch to Ethereum
                      </StakingButton>
                    ) : !!account && isUnstake ? (
                      <StakingButton
                        onClick={handleUnstake}
                        loading={loading}
                        disabled={!isDeadline}
                      >
                        UNSTAKE
                      </StakingButton>
                    ) : isDisableApproveBtn ? (
                      <StakingButton
                        onClick={() => handleApprove()}
                        loading={loading}
                        disabled={!account}
                      >
                        Approve
                      </StakingButton>
                    ) : (
                      <StakingButton
                        onClick={handleStake}
                        loading={loading}
                        disabled={
                          !reward || Number(tokenInfo?.balanceOf) < amount
                        }
                      >
                        STAKE
                      </StakingButton>
                    )}
                  </>
                ) : (
                  <WalletNotConnectedWrapper>
                    <WalletIcon />
                    <span>Wallet not connected</span>
                  </WalletNotConnectedWrapper>
                )}

                <GradientMobile src={gradientMobile} />
                <GradientTop src={gradient} />
              </StakingActionContainer>
            </StakeWrapper>
          </RowWrapper>
          <RowWrapper>
            <StakeWrapper>
              <AnswerQuestionContainer>
                <h2>FAQS</h2>
                <FAQSContainer>
                  <FAQSContent>
                    <h4>• &nbsp; What is Staking?</h4>
                    <div>
                      Staking is a way to earn rewards by locking up your
                      credits for a fixed term.
                    </div>
                  </FAQSContent>
                  <FAQSContent>
                    <h4>• &nbsp; How do I withdraw my rewards?</h4>
                    <div>
                      You can withdraw your rewards at the end of your staking
                      term.
                    </div>
                  </FAQSContent>
                  <FAQSContent>
                    <h4>• &nbsp; Is there a penalty for early withdrawal?</h4>
                    <div>
                      Early withdrawal may incur penalties, depending on the
                      terms selected.
                    </div>
                  </FAQSContent>
                </FAQSContainer>
              </AnswerQuestionContainer>
            </StakeWrapper>
            <StakeWrapper>
              <AnswerQuestionContainer>
                <h3>Stake BIGA for USDT</h3>
                <FAQSContainer>
                  <div>The longer you stake, the higher you earn.</div>
                  <div className="amount-group">
                    <strong>24M = 50%</strong>
                    <strong>12M = 30%</strong>
                    <strong>6M = 15%</strong>
                  </div>
                  <div>Yield is paid monthly.</div>
                </FAQSContainer>
              </AnswerQuestionContainer>
            </StakeWrapper>
          </RowWrapper>
        </StakingBody>
        <RowWrapper>
          <StakingFooter stakeData={stakeData} />
        </RowWrapper>
        <GradientBottom src={gradient} />
      </StakingPageTemplate>
    </HomepageTemplate>
  );
};
