import { Row } from 'antd';
import { formatUnits } from 'ethers/lib/utils';
import { useGetGameInfo } from 'hooks/games/useGetGameInfo';
import { useGetListLevelFee } from 'hooks/level-fee/useGetLevelFeeList';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { LeaderboardTable, StyledPagination } from './styled';

export const LevelFeeTable = ({
  gameId,
  refresh,
}: {
  gameId: string;
  refresh: boolean;
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [update, setUpdate] = useState(false);

  const [params, setParams] = useState({
    pageSize: 6,
    pageIndex: currentPage,
    gameId: undefined,
  });

  useEffect(() => {
    setParams(prevParams => ({
      ...prevParams,
      gameId,
    }));
  }, [gameId]);

  const {
    listLevel,
    total,
    loading: listLevelLoading,
  } = useGetListLevelFee(params, refresh || update);
  const { gameInfo, loading: gameInfoLoading } = useGetGameInfo(gameId);

  const columns: Array<{
    title: string;
    dataIndex: string;
    key: string;
    render?: (value: any, record: any) => React.ReactNode;
  }> = [
    {
      title: 'Level',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Name',
      dataIndex: 'levelName',
      key: 'levelName',
    },
    {
      title: 'Fee',
      dataIndex: 'entryFee',
      key: 'entryFee',
      render: value => {
        return value && gameInfo
          ? formatUnits(value, gameInfo.tokenInfo.decimals) +
              ` ${gameInfo.tokenInfo.symbol}`
          : 0;
      },
    },
  ];

  return (
    <>
      <LeaderboardTable
        dataSource={listLevel?.map(item => ({ ...item, key: item.level }))}
        columns={columns}
        pagination={false}
        loading={listLevelLoading || gameInfoLoading}
        light={'true'}
      />
      <Row style={{ justifyContent: 'center' }} className="mb-5">
        {total > 6 && (
          <StyledPagination
            className="mt-4"
            pageSize={6}
            current={currentPage}
            onChange={page => {
              setCurrentPage(page);
              setParams({
                ...params,
                pageIndex: page,
              });
            }}
            total={total}
            showSizeChanger={false}
            light="true"
          />
        )}
      </Row>
    </>
  );
};
