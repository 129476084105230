import { useEffect, useState } from 'react';
import { getSecurityFlagList } from 'services/api';

export interface ISecurityFlagList {
  username: string;
  avatarURL?: string;
  time: string;
  game: string;
  level: string;
}

const mockData = [
  {
    username: 'john_doe',
    avatarURL:
      'https://dkbbk202xfl5t.cloudfront.net/avatar/64d48a02e273719bdd1e0202',
    time: '2024-11-26T08:30:00',
    game: 'Game A',
    level: '5',
    status: true,
  },
  {
    username: 'jane_smith',
    time: '2024-11-26T09:00:00',
    game: 'Game B',
    level: '10',
    status: true,
  },
  {
    username: 'michael_lee',
    avatarURL:
      'https://dkbbk202xfl5t.cloudfront.net/avatar/64d48a02e273719bdd1e0202',
    time: '2024-11-25T14:15:00',
    game: 'Game C',
    level: '3',
    status: false,
  },
  {
    username: 'lucy_williams',
    time: '2024-11-24T12:45:00',
    game: 'Game A',
    level: '7',
    status: true,
  },
];

export const useGetSecurityFlagList = (params, refresh: boolean) => {
  const [securityFlagList, setSecurityFlagList] =
    useState<ISecurityFlagList[]>(undefined);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      setLoading(true);
      const res = await getSecurityFlagList(params);
      if (res?.success) {
        // setData
        // setSecurityFlagList(res?.payload?.data?.data);

        //mock data
        setSecurityFlagList(mockData);
        setTotal(res?.payload?.data?.totalCount);
      }
      setLoading(false);
    })();
  }, [params, refresh]);
  return { securityFlagList, total, loading };
};
