import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { Dropdown, Tooltip } from 'antd';
import metaMaskIcon from 'assets/logo-metamask.png';
import geminiIcon from 'assets/vectors/login-gemini.svg';
import { LinkWalletModal } from 'components/Modals/LinkWallet';
import { PlayerStatus } from 'constants/enum/PlayerStatus';
import { PitRouter } from 'constants/routers';
import { formatUnits } from 'ethers/lib/utils';
import { useGetPlayer } from 'hooks/player/useGetPlayer';
import { useGetRemainingBalance } from 'hooks/token-balance/useGetRemainingBalance';
import useWagmiConnectors from 'hooks/useWagmiConnectors';
import { useDisconnect } from 'hooks/useDisconnect';
import { useLogOut } from 'hooks/useLogOut';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setSignar } from 'redux/slices/signar';
import { loginWallet } from 'redux/slices/user';
import { useAppSelector } from 'redux/store';
import { LocalhostStorage } from 'utils/sessionStorage';
import { injected, WalletConnectInstance } from 'utils/wallets/connector';
import bigalogo from 'assets/vectors/logo-navbar-biga.svg';
import defaultProfileImg from 'assets/images/default-user-profile.png';
import useWindowSize from 'hooks/useWindowSize';

import {
  BigaCreditsLogo,
  BigaCreditsTextWrapper,
  BigaCreditsText,
  BigaCreditsValueText,
  BigaCreditsWrapper,
  ConnectButtonText,
  ConnectButtonWrapper,
  ConnectDecriptionText,
  ConnectTitleText,
  ConnectWalletText,
  ConnectWalletWrapper,
  ConnectWalletButton,
  HeaderWrap,
  HeaderWrapper,
  Line,
  LogoHeaderRedDot,
  MenuMainButton,
  MenuMainButtonLogo,
  MenuMainButtonText,
  MenuMainButtonWrapper,
  MenuMainDecriptionStepsText,
  MenuMainDecriptionText,
  MenuMainDecriptionWrapper,
  MenuMainTextWrapper,
  MenuMainWrapper,
  MenuWrapper,
  LogoHeaderWallet,
  LogoWrapper,
  Logo,
  DropdownWrapper,
  DropdownItemWrapper,
  ProfileWrapper,
  ProfileMain,
  ProfileAvatarWrapper,
  ProfileAvatar,
  UserInfoWrapper,
} from './styled';
import { PoweroffOutlined, SolutionOutlined } from '@ant-design/icons';
import { LogoutModal } from 'components/Modals/UserProfile/LogoutModal';
import { useAccount } from 'wagmi';

interface IHeaderProps {
  theme?: string;
}

export default function Header({ theme }: IHeaderProps) {
  const { address } = useAccount();
  const { activate } = useWeb3React<Web3Provider>();
  const { onConnectWallet, onDisconnect } = useWagmiConnectors({
    mutation: {
      onError: () => {
        setConnectMetamaskButtonClick(false);
      },
    },
  });
  const logOut = useLogOut();
  const { refreshToken } = useAppSelector(state => state.refreshToken);
  const [visible, setVisible] = useState(false);
  const [visibleAvatarDropdown, setVisibleAvatarDropdown] = useState(false);
  const [account, setAccount] = useState('');
  const [connectMetamaskButtonClick, setConnectMetamaskButtonClick] =
    useState<boolean>(false);

  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const { width } = useWindowSize();

  const { remainingBalance } = useGetRemainingBalance(
    process.env.REACT_APP_DEFAULT_TOKEN_ADDRESS,
    refreshToken,
  );

  const CHAIN_ID = LocalhostStorage.get('chainId');
  const dispatch = useDispatch();
  const disconnect = useDisconnect();

  useEffect(() => {
    if (width <= 767) setShowMobileMenu(true);
    else setShowMobileMenu(false);
  }, [width, setShowMobileMenu]);

  useEffect(() => {
    void (() => {
      const isConnected = Boolean(LocalhostStorage.get('isConnected'));
      const typeOfConnector = LocalhostStorage.get('typeOfConnector');

      if (isConnected && typeOfConnector === 'Metamask') {
        activate(injected(CHAIN_ID));
        dispatch(loginWallet(LocalhostStorage.get('account')));
      }

      if (isConnected && typeOfConnector === 'Walletconnect') {
        activate(WalletConnectInstance);
        dispatch(loginWallet(LocalhostStorage.get('account')));
      }
    })();
  }, [CHAIN_ID]);

  useEffect(() => {
    const isConnected = Boolean(LocalhostStorage.get('isConnected'));
    const walletAddress = LocalhostStorage.get('account');
    dispatch(setSignar(LocalhostStorage.get('isConnected')));

    if (!isEmpty(address)) {
      if (
        isConnected &&
        address?.toUpperCase() !== walletAddress?.toUpperCase()
      ) {
        disconnect();
      }
    }
  }, [address]);

  const [refresh, setRefresh] = useState<boolean>(false);
  const { loading } = useGetPlayer(null, refresh);
  const navigate = useNavigate();

  const handleOk = () => {
    logOut();
    navigate(PitRouter.GAME_LIST);
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleNavigaLogin = () => {
    navigate(PitRouter.LOGIN);
  };

  const handleNavigaRegister = () => {
    navigate(PitRouter.REGISTER);
  };

  useLogOut({
    refresh,
    onRefresh: () => {
      setRefresh(!refresh);
    },
  });

  const { playerInfo, refreshAvatar } = useAppSelector(state => state.player);
  const avatarURL = useMemo(() => {
    return playerInfo?.avatarURL
      ? `${playerInfo?.avatarURL}?timestamp=${Date.now()}`
      : '';
  }, [playerInfo?.avatarURL, refreshAvatar]);

  useEffect(() => {
    if (
      (address &&
        playerInfo &&
        playerInfo.walletAddress &&
        address === playerInfo.walletAddress) ||
      !playerInfo.walletAddress
    ) {
      setAccount(address);
      return;
    }
    if (
      connectMetamaskButtonClick &&
      address &&
      playerInfo &&
      playerInfo.walletAddress &&
      address !== playerInfo.walletAddress
    ) {
      toast.error(
        'Please login to the account linked to the connected wallet address',
      );
      onDisconnect();
      setAccount('');
      setConnectMetamaskButtonClick(false);
      return;
    }

    if (!address) {
      setAccount('');
      return;
    }
  }, [address, playerInfo]);

  const handleLogOutClick = () => {
    setVisibleAvatarDropdown(false);
    setVisible(true);
  };

  const handleProfileClick = () => {
    setVisibleAvatarDropdown(false);
    navigate(PitRouter.PROFILE);
  };

  const handleConnectMetamask = () => {
    setConnectMetamaskButtonClick(true);
    onConnectWallet();
  };

  const menu = (
    <MenuWrapper>
      <MenuMainWrapper>
        <MenuMainTextWrapper>
          <ConnectTitleText>Connect Wallet</ConnectTitleText>
          <ConnectDecriptionText>
            Connect your wallet by selecting below options.
          </ConnectDecriptionText>
        </MenuMainTextWrapper>

        <MenuMainButtonWrapper>
          <MenuMainButton onClick={handleConnectMetamask}>
            <MenuMainButtonLogo src={metaMaskIcon} />
            <MenuMainButtonText>Connect Metamask</MenuMainButtonText>
          </MenuMainButton>
          <Tooltip title="Coming soon">
            <MenuMainButton disabled>
              <MenuMainButtonLogo src={geminiIcon} />
              <MenuMainButtonText>Connect Gemini</MenuMainButtonText>
            </MenuMainButton>
          </Tooltip>
        </MenuMainButtonWrapper>

        <MenuMainDecriptionWrapper>
          <MenuMainDecriptionText>
            Don&apos;t know how to connect your wallet? Follow these simple {''}
            <MenuMainDecriptionStepsText
              href="https://support.metamask.io/getting-started/getting-started-with-metamask/"
              target="_blank"
            >
              steps
            </MenuMainDecriptionStepsText>{' '}
            to set up
          </MenuMainDecriptionText>
        </MenuMainDecriptionWrapper>
      </MenuMainWrapper>
    </MenuWrapper>
  );

  const widgetMenu = (
    <DropdownWrapper>
      <DropdownItemWrapper key="profile" onClick={handleProfileClick}>
        <SolutionOutlined />
        Profile
      </DropdownItemWrapper>
      <DropdownItemWrapper key="logout" onClick={handleLogOutClick}>
        <PoweroffOutlined />
        Logout
      </DropdownItemWrapper>
    </DropdownWrapper>
  );

  const { hasBackground } = useAppSelector(root => root.template);
  return (
    <HeaderWrap>
      <HeaderWrapper hasBackground={hasBackground}>
        {showMobileMenu ? (
          <>
            <LogoWrapper>
              <Logo
                src={bigalogo}
                alt="logo"
                onClick={() => navigate(PitRouter.HOME)}
              />
            </LogoWrapper>
            {playerInfo &&
            playerInfo.status === PlayerStatus.UploadedProfile ? (
              <UserInfoWrapper>
                <ConnectWalletWrapper>
                  {account && <LogoHeaderRedDot active={!!account} />}{' '}
                  {account ? (
                    <CopyToClipboard
                      onCopy={() => {
                        toast.success('COPY successfully');
                      }}
                      text={account}
                    >
                      <ConnectWalletText>{`${account.substring(
                        0,
                        6,
                      )}...${account.substring(38)}`}</ConnectWalletText>
                    </CopyToClipboard>
                  ) : (
                    <Dropdown overlay={menu} trigger={['click']}>
                      <ConnectWalletText>
                        {account ? (
                          <span>{`${account.substring(
                            0,
                            6,
                          )}...${account.substring(38)}`}</span>
                        ) : (
                          <ConnectWalletButton>
                            <LogoHeaderWallet />
                            Connect wallet
                          </ConnectWalletButton>
                        )}
                      </ConnectWalletText>
                    </Dropdown>
                  )}
                </ConnectWalletWrapper>
                <LinkWalletModal
                  refresh={refresh}
                  onRefresh={() => {
                    setRefresh(!refresh);
                  }}
                />

                <ProfileWrapper>
                  <Dropdown
                    open={visibleAvatarDropdown}
                    overlay={widgetMenu}
                    trigger={['click']}
                    placement="bottomLeft"
                    overlayStyle={{ position: 'fixed' }}
                    onOpenChange={open => setVisibleAvatarDropdown(open)}
                  >
                    <ProfileMain>
                      <ProfileAvatarWrapper>
                        <ProfileAvatar
                          src={avatarURL ? avatarURL : defaultProfileImg}
                          alt="avatar profile"
                        />
                      </ProfileAvatarWrapper>
                      <LogoutModal
                        isOpen={visible}
                        onCancel={handleCancel}
                        onOk={handleOk}
                      />
                    </ProfileMain>
                  </Dropdown>
                </ProfileWrapper>
              </UserInfoWrapper>
            ) : (
              <ConnectButtonWrapper>
                <ConnectButtonText onClick={handleNavigaLogin}>
                  Login
                </ConnectButtonText>
                <Line />
                <ConnectButtonText onClick={handleNavigaRegister}>
                  Register
                </ConnectButtonText>
              </ConnectButtonWrapper>
            )}
          </>
        ) : (
          <>
            {playerInfo &&
            playerInfo.status === PlayerStatus.UploadedProfile ? (
              <>
                <BigaCreditsWrapper>
                  <BigaCreditsLogo />
                  <BigaCreditsTextWrapper>
                    <BigaCreditsText>BIGA credits: </BigaCreditsText>
                    <BigaCreditsValueText>
                      {remainingBalance?.amount ? (
                        <>
                          {parseFloat(
                            formatUnits(
                              remainingBalance.amount,
                              remainingBalance.tokenInfo?.decimals || 18,
                            ),
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            useGrouping: true,
                          })}
                          {' ($'}
                          {(
                            parseFloat(
                              formatUnits(
                                remainingBalance.amount,
                                remainingBalance.tokenInfo?.decimals || 18,
                              ),
                            ) / 10
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            useGrouping: true,
                          })}
                          {')'}
                        </>
                      ) : (
                        0
                      )}
                    </BigaCreditsValueText>
                  </BigaCreditsTextWrapper>
                </BigaCreditsWrapper>
                {account && <Line />}
                <ConnectWalletWrapper>
                  {account && <LogoHeaderRedDot active={!!account} />}{' '}
                  {account ? (
                    <CopyToClipboard
                      onCopy={() => {
                        toast.success('COPY successfully');
                      }}
                      text={account}
                    >
                      <ConnectWalletText>{`${account.substring(
                        0,
                        6,
                      )}...${account.substring(38)}`}</ConnectWalletText>
                    </CopyToClipboard>
                  ) : (
                    <Dropdown overlay={menu} trigger={['click']}>
                      <ConnectWalletText>
                        {account ? (
                          <span>{`${account.substring(
                            0,
                            6,
                          )}...${account.substring(38)}`}</span>
                        ) : (
                          <ConnectWalletButton>
                            <LogoHeaderWallet />
                            Connect wallet
                          </ConnectWalletButton>
                        )}
                      </ConnectWalletText>
                    </Dropdown>
                  )}
                </ConnectWalletWrapper>
                <LinkWalletModal
                  refresh={refresh}
                  onRefresh={() => {
                    setRefresh(!refresh);
                  }}
                />
              </>
            ) : (
              <ConnectButtonWrapper>
                <ConnectButtonText onClick={handleNavigaLogin}>
                  Login
                </ConnectButtonText>
                <Line />
                <ConnectButtonText onClick={handleNavigaRegister}>
                  Register
                </ConnectButtonText>
              </ConnectButtonWrapper>
            )}
          </>
        )}
      </HeaderWrapper>
      {showMobileMenu &&
      playerInfo &&
      playerInfo.status === PlayerStatus.UploadedProfile ? (
        <BigaCreditsWrapper>
          <BigaCreditsLogo />
          <BigaCreditsTextWrapper>
            <BigaCreditsValueText>
              {remainingBalance?.amount ? (
                <>
                  {parseFloat(
                    formatUnits(
                      remainingBalance.amount,
                      remainingBalance.tokenInfo?.decimals || 18,
                    ),
                  )
                    .toLocaleString('de-DE', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      useGrouping: true,
                    })
                    .replace(/\./g, ' ')}
                  {' ($'}
                  {(
                    parseFloat(
                      formatUnits(
                        remainingBalance.amount,
                        remainingBalance.tokenInfo?.decimals || 18,
                      ),
                    ) / 10
                  )
                    .toLocaleString('de-DE', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                      useGrouping: true,
                    })
                    .replace(/\./g, ' ')}
                  {')'}
                </>
              ) : (
                0
              )}
            </BigaCreditsValueText>
          </BigaCreditsTextWrapper>
        </BigaCreditsWrapper>
      ) : (
        <></>
      )}
    </HeaderWrap>
  );
}
