import { Button } from 'antd';
import styled from 'styled-components';
import { UpdateInformationModal } from '../UserProfile/UpdateInformationModal';

export const StyledModal = styled(UpdateInformationModal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Description = styled.div`
  text-align: center;
  font-family: 'Neue Haas Grotesk Display Pro';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #fff;
  padding: 30px 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

export const LinkButton = styled(Button)<{ height?: string; width?: string }>`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  border-radius: 12px;
  line-height: normal;
  padding: 12px 24px;
  background: #070709;
  box-shadow: 0px 8px 8px -4px rgba(255, 255, 255, 0.12);
  font-size: 16px;
  font-weight: 600;
  user-select: none;
  outline: 0;
  border: none;
  height: 40px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.ant-btn:active:not(:disabled),
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    background: #15151b;
    opacity: 0.7;
    outline: 0;
  }
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    color: #fff;
    border: none;
    background: #15151b;
    opacity: 0.7;
    outline: 0;
  }
`;

export const SubmitButton = styled(Button)<{ height?: string; width?: string }>`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  border-radius: 12px;
  line-height: normal;
  padding: 12px 24px;
  background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 187, 255, 0.24),
    0px 2px 4px -1px rgba(10, 70, 82, 0.12),
    0px 16px 24px 0px rgba(0, 187, 255, 0.24),
    0px 8px 8px -4px rgba(0, 187, 255, 0.12);
  font-size: 16px;
  font-weight: 600;
  user-select: none;
  height: 40px;
  width: 150px;
  outline: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &.ant-btn:active:not(:disabled),
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
    opacity: 0.7;
    outline: 0;
  }
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    color: #fff;
    border: none;
    background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
    opacity: 0.7;
    outline: 0;
  }
`;
