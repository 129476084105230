import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { BigNumber } from 'ethers';
import { useBigaStaking } from './useBigaStaking';
import { useAccount } from 'wagmi';

export type StakeParams = {
  amount: BigNumber;
  term: number;
};

export const useStakeCallback = () => {
  const { address: account } = useAccount();
  const stakingContract = useBigaStaking();

  const stake = useCallback(
    async (param: StakeParams) => {
      try {
        if (stakingContract && account) {
          const gasLimit = await stakingContract.estimateGas.stake(
            param.amount,
            param.term,
          );
          const tx = await stakingContract.stake(param.amount, param.term, {
            gasLimit: BigNumber.from(gasLimit).mul(2),
          });
          return tx.wait(1);
        }
      } catch (e) {
        console.log(e.message);
        toast.error(e.reason || e.message);
      }
    },
    [account, stakingContract],
  );

  const withdrawStake = useCallback(async () => {
    try {
      if (stakingContract && account) {
        const gasLimit = await stakingContract.estimateGas.withdrawStake();
        const tx = await stakingContract.withdrawStake({
          gasLimit: BigNumber.from(gasLimit).mul(2),
        });
        return tx.wait(1);
      }
    } catch (e) {
      console.log(e.message);
      toast.error(e.reason || e.message);
    }
  }, [account, stakingContract]);

  const stakes = useCallback(
    async (address: string) => {
      try {
        if (stakingContract && account) {
          const tx = await stakingContract.stakes(address);
          return tx;
        }
      } catch (e) {
        console.log(e.message);
      }
    },
    [account, stakingContract],
  );

  const getTermDeadline = useCallback(
    async (startTime: string, term: number) => {
      try {
        if (stakingContract && account) {
          const tx = await stakingContract.getTermDeadline(startTime, term);
          return tx;
        }
      } catch (e) {
        console.log(e.message);
      }
    },
    [account, stakingContract],
  );

  return {
    stake,
    withdrawStake,
    stakes,
    getTermDeadline,
  };
};
