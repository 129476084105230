import styled from 'styled-components';
import { Input, Select, Slider, Carousel, Button, Divider } from 'antd';
import background from 'assets/images/staking/BG.png';
import backgroundMobile from 'assets/images/staking/BG-mobile.png';

export const StakingPageTemplate = styled.div`
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
  background-color: #0f0f13;
  color: #fff;
  position: relative;
  overflow: hidden;
  @media (max-width: 1440px) {
    margin-left: 20px;
  }
  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

export const StakingHeader = styled.h1`
  font-size: 32px;
  font-weight: 700;
  display: none;
  color: #fff;
  margin: 30px 0;
  align-items: center;
  justify-content: center;
  column-gap: 12px;

  @media (max-width: 1024px) {
    display: flex;
  }
`;

export const StakingBody = styled.div`
  padding: 20px 180px 0 80px;

  @media (max-width: 1600px) {
    padding: 20px 110px 0 70px;
  }

  @media (max-width: 1440px) {
    padding: 20px 110px 0 50px;
  }

  @media (max-width: 1024px) {
    padding: 0px 80px 0 40px;
  }

  @media (max-width: 767px) {
    padding: 0 40px;
  }

  @media (max-width: 550px) {
    padding: 0 12px;
  }
`;

export const StakeWrapper = styled.div`
  width: 50%;
  display: flex;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const APYWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 550px) {
    row-gap: 6px;
  }
`;

export const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
    row-gap: 20px;
    align-items: center;

    &.banner-tabs-wrapper {
      row-gap: 0;
    }
  }
`;

export const InputRow = styled(RowWrapper)`
  column-gap: 40px;
  align-items: center;
  > label {
    font-size: 20px;
    width: 50%;
  }

  @media (max-width: 1800px) {
    column-gap: 20px;
  }

  @media (max-width: 1440px) {
    > label {
      font-size: 16px;
      box-sizing: border-box;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: row;
    justify-content: center;

    > label {
      &.opacity {
        opacity: 0.7;
      }
    }
  }

  @media (max-width: 550px) {
    flex-direction: column;
    align-items: center;
    row-gap: 12px;

    > label {
      text-align: center;
      color: #a9a9a9;
    }

    &.deadline-biga-wrapper {
      flex-direction: column-reverse;
    }
  }
`;

export const StakingInput = styled(Input)`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  width: 100%;
  border-radius: 8px;
  padding: 15px 5px;
  background-color: #1f1f26;
  border: none;

  &:focus,
  &:hover {
    border: none;
    box-shadow: none;
  }

  &.ant-input-disabled {
    background-color: #1f1f26;
    color: #fff;
  }

  @media (max-width: 1600px) {
    font-size: 25px;
  }

  @media (max-width: 1100px) {
    font-size: 20px;
  }
  @media (max-width: 550px) {
    font-size: 32px;
  }
`;

export const StakingActionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  background: radial-gradient(
    98.99% 98.99% at 50% 1.01%,
    #0f0f13 0%,
    #161923 100%
  );
  padding: 0 60px;
  height: 825px;
  width: 100%;
  justify-content: center;
  flex-shrink: 0;
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  overflow: hidden;

  &.how-to-earn-mobile-wrapper {
    display: none;
  }

  @media (max-width: 1600px) {
    height: 700px;
  }

  @media (max-width: 1550px) {
    padding: 0 30px;
  }

  @media (max-width: 1440px) {
    gap: 30px;
  }

  @media (max-width: 1280px) {
    height: 650px;
  }

  @media (max-width: 1024px) {
    gap: 30px;
    height: unset;
    padding: 20px 30px 30px;
    background: #15151b;

    &.staking-form {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 25px;
    }

    &.hidden {
      display: none;
    }

    &.how-to-earn-mobile-wrapper {
      display: flex;
      padding: 20px 0 30px;
      border-top-right-radius: 0;
      border-bottom-left-radius: 25px;
      border-top-left-radius: 0;
    }
  }

  @media (max-width: 550px) {
    row-gap: 20px;
    padding: 20px 10px 30px;

    &.hidden {
      display: none;
    }
  }
`;

export const LargeLabel = styled.div`
  color: var(--Color-3, #a9a9a9);
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;

  @media (max-width: 1600px) {
    font-size: 20px;
  }

  @media (max-width: 1100px) {
    font-size: 16px;
  }

  @media (max-width: 550px) {
    font-weight: 400;
    text-transform: unset;
  }
`;

export const SelectStyled = styled(Select)`
  &&:not(.ant-select-customize-input) .ant-select-selector {
    &:hover,
    &:focus {
      border-color: rgba(30, 30, 30, 0.9);
      box-shadow: none;
    }
  }

  &.ant-select {
    width: 50%;
    .ant-select-selector {
      height: 54px;
      border-radius: 8px;
      border-color: rgba(30, 30, 30, 0.9);
      background-color: #111115;
      color: #727277;
      align-items: center;
      display: flex;

      &:hover,
      &:focus {
        border-color: rgba(30, 30, 30, 0.9);
        box-shadow: none;
      }

      span {
        font-size: 14px;
        font-weight: 500;
      }
    }

    &.ant-select-disabled.ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      background-color: #111115;
    }

    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      border-color: rgba(30, 30, 30, 0.9);
      box-shadow: none;
    }
  }

  .ant-select-arrow {
    color: #fff;
  }

  .ant-select-selection {
    background-color: #1f1f26;
  }

  &.ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: #fff;
    background-color: #1f1f26;
  }

  @media (max-width: 1024px) {
    &.opacity {
      opacity: 0.7;
    }
  }

  @media (max-width: 550px) {
    &.ant-select {
      width: 100%;
      .ant-select-selector {
        height: 49px;
      }
    }
  }
`;

export const CustomSlider = styled(Slider)`
  &.ant-slider {
    width: 48%;

    &:hover {
      .ant-slider-track {
        background: #e961ff;
      }
    }
  }

  .ant-slider-track {
    background: #e961ff;

    &:hover {
      background: #e961ff;
    }
  }

  .ant-slider-handle {
    border: none;
    background: #d9d9d9;

    &:hover {
      background: #d9d9d9;
    }
  }

  .ant-slider-rail {
    background: #111115;
  }

  @media (max-width: 1024px) {
    &.opacity {
      opacity: 0.7;
      pointer-events: none;
    }
  }

  @media (max-width: 550px) {
    &.ant-slider {
      width: 97%;
      margin: 0;
    }
  }
`;

export const CarouselWrapper = styled.div`
  background-image: url(${background});
  background-repeat: no-repeat;
  height: 825px;
  width: 100%;
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;

  @media (max-width: 1600px) {
    height: 700px;
  }

  @media (max-width: 1280px) {
    height: 650px;
  }

  @media (max-width: 1024px) {
    padding: 20px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
    box-sizing: border-box;
    border-bottom-left-radius: unset;
    border-top-right-radius: 25px;
  }

  @media (max-width: 550px) {
    height: fit-content;
    min-height: 260px;
    background-size: 100% 100%;
    background-image: url(${backgroundMobile});
  }
`;

export const CustomCarousel = styled(Carousel)`
  width: 100%;
  height: 800px;

  .slick-list {
    height: 825px;
  }

  .slick-dots li button {
    border-radius: 100px;
    width: 10px;
    height: 10px;
  }

  @media (max-width: 1600px) {
    height: 680px;
    .slick-list {
      height: 700px;
    }
  }

  @media (max-width: 1280px) {
    height: 630px;
    .slick-list {
      height: 650px;
    }
  }

  @media (max-width: 1024px) {
    display: none !important;
  }
`;

export const BigaEarnAPY = styled.div`
  margin-top: 210px;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 108px;
  font-weight: 700;
  line-height: 100px;
  letter-spacing: 5px;
  text-transform: uppercase;
  text-align: center;
  padding: 0 10px;

  @media (max-width: 1700px) {
    font-size: 70px;
  }

  @media (max-width: 1600px) {
    margin-top: 150px;
    font-size: 60px;
  }

  @media (max-width: 1100px) {
    font-size: 50px;
    margin-top: 150px;
  }
`;

export const BigaEarnAPYMobile = styled.div`
  display: none;
  text-transform: uppercase;

  @media (max-width: 1024px) {
    display: block;
    text-align: center;
    font-size: 50px;
    font-weight: 700;
  }
`;

export const HowToEarn = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 64px;
  font-weight: 700;
  line-height: 100px;
  letter-spacing: 5px;
  text-transform: uppercase;
  text-align: center;

  @media (max-width: 1800px) {
    font-size: 55px;
  }

  @media (max-width: 1656px) {
    font-size: 40px;
  }

  @media (max-width: 1600px) {
    margin-top: 20px;
    margin-bottom: 0px;
  }

  @media (max-width: 1550px) {
    font-size: 30px;
  }

  @media (max-width: 1280px) {
    line-height: 80px;
    margin-top: 10px;
  }

  @media (max-width: 1040px) {
    font-size: 28px;
  }

  @media (max-width: 1024px) {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (max-width: 450px) {
    margin-top: 0;
    font-size: 25px;
    line-height: 80px;
  }
`;

export const StepContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;

export const StepContent = styled.div`
  color: #fff;
  font-size: 16px;
  padding: 14px 16px;
  max-width: 71%;
  border-radius: 10px;
  background-color: rgba(7, 7, 9, 0.4);
  position: relative;

  > h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  @media (max-width: 1860px) {
    &.step-content-second {
      margin-top: 0px;
    }
    &.step-content-final {
      margin-top: -10px;
    }
  }

  @media (max-width: 1687px) {
    &.step-content-second {
      margin-top: -10px;
    }
  }

  @media (max-width: 1600px) {
    margin-top: 0;
    max-width: 85%;

    &.step-content-second {
      margin-top: 0px;
    }

    &.step-content-final {
      margin-top: 40px;
    }
  }

  @media (max-width: 1440px) {
    &.step-content-first {
      margin-top: 30px;
    }
    &.step-content-final {
      margin-top: -10px;
    }
  }

  @media (max-width: 1280px) {
    &.step-content-final {
      margin-top: 0px;
    }
  }

  @media (max-width: 1266px) {
    &.step-content-first {
      margin-top: 0px;
    }
  }

  @media (max-width: 1142px) {
    padding: 5px 10px;
    &.step-content-first {
      margin-top: 20px;
    }
  }

  @media (max-width: 1073px) {
    &.step-content-first {
      margin-top: 0;
    }
  }

  @media (max-width: 1024px) {
    max-width: 100%;
    background-color: #1f1f26;
    padding: 10px 8px;
    text-align: center;
    font-size: 14px;
    line-height: 1.6;
  }
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  padding-left: 85px;

  @media (max-width: 1750px) {
    row-gap: 40px;
  }

  @media (max-width: 1600px) {
    padding-left: 40px;
    row-gap: 20px;
  }

  @media (max-width: 1400px) {
    margin-top: -10px;
  }

  @media (max-width: 1280px) {
    padding-left: 20px;
    row-gap: 10px;
  }

  @media (max-width: 1024px) {
    padding: 0 30px;
    row-gap: 20px;
  }

  @media (max-width: 550px) {
    padding: 0 13px;
  }
`;

export const StakingButton = styled(Button)`
  font-size: 20px;
  border-radius: 50px;
  font-weight: 700;
  background: linear-gradient(90deg, #0038ff -54.32%, #00aafd 100%), #fff;
  box-shadow: 0px 0px 1px 0px rgba(0, 187, 255, 0.24),
    0px 2px 4px -1px rgba(10, 70, 82, 0.12),
    0px 16px 24px 0px rgba(0, 187, 255, 0.24),
    0px 8px 8px -4px rgba(0, 187, 255, 0.12);
  border: none;
  color: #fff;
  display: flex;
  min-width: 394px;
  height: 58px;
  padding: 12px 22px;
  justify-content: center;
  align-items: center;

  @media (max-width: 1600px) {
    min-width: 70%;
  }

  @media (max-width: 1024px) {
    border-radius: 12px;
    font-size: 18px;
    text-transform: capitalize;
    box-shadow: none;
    min-width: 80%;

    &.opacity {
      opacity: 0.7;
      pointer-events: none;
    }
  }
`;

export const AmountWrapper = styled.div`
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: right;
  > span {
    font-size: 20px;
    width: 100%;
    font-weight: 700;
  }

  @media (max-width: 1440px) {
    > span {
      font-size: 16px;
    }
  }
`;

export const DeadlineWrapper = styled(AmountWrapper)`
  text-align: left;
  justify-content: left;
  > span {
    font-size: 20px;
    width: 100%;
    font-weight: 400;
  }

  @media (max-width: 1440px) {
    > span {
      font-size: 16px;
    }
  }

  @media (max-width: 1100px) {
    > span {
      font-size: 16px;
    }
  }
`;

export const AnswerQuestionContainer = styled.div`
  width: 100%;
  padding: 150px 0;

  > h2 {
    color: #b5b5b5;
    font-size: 64px;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
    margin-bottom: 50px;
  }

  > h3 {
    color: #fff;
    font-size: 36px;
    font-weight: 700;
    margin-top: 30px;
    text-align: center;
    margin-bottom: 50px;
  }

  @media (max-width: 1100px) {
    > h2 {
      font-size: 40px;
    }

    > h3 {
      font-size: 30px;
    }
  }

  @media (max-width: 1024px) {
    padding: 50px 0 0 0;

    > h2 {
      margin-bottom: 0;
    }

    > h3 {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  @media (max-width: 550px) {
    padding: 0;
    > h2 {
      font-size: 18px;
      margin-bottom: 20px;
      margin-top: 50px;
    }

    > h3 {
      margin-top: 30px;
      margin-bottom: 20px;
      font-size: 18px;
      color: #a9a9a9;
    }
  }
`;

export const FAQSContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  width: 100%;

  > div {
    font-size: 24px;
  }

  .amount-group {
    display: flex;
    flex-direction: column;
    align-items: center;

    strong {
      font-size: 24px;
    }
  }

  @media (max-width: 1100px) {
    > div {
      font-size: 16px;
    }

    .amount-group {
      strong {
        font-size: 20px;
        color: #fff;
      }
    }
  }

  @media (max-width: 1024px) {
    padding: 30px 18px 0 18px;
    row-gap: 20px;
  }

  @media (max-width: 550px) {
    padding: 40px 20px;
    background: #15151b;
    border-radius: 23px;

    > div {
      font-weight: 500;
      text-align: center;
      color: #a9a9a9;
    }
  }
`;

export const FAQSContent = styled.div`
  color: #e8e8e8;
  font-size: 16px;
  text-align: center;

  > h4 {
    color: #e8e8e8;
    font-size: 20px;
    font-weight: 700;
  }

  @media (max-width: 550px) {
    font-weight: 500;
    color: #a9a9a9;
  }
`;

export const GradientTop = styled.img`
  position: absolute;
  top: -500px;
  width: 1700px;
  right: -550px;
  z-index: 0;
  pointer-events: none;

  @media (max-width: 1400px) {
    right: -650px;
  }

  @media (max-width: 1024px) {
    top: 450px;
    width: 800px;
    right: -150px;
  }

  @media (max-width: 550px) {
    display: none;
  }
`;

export const GradientBottom = styled.img`
  position: absolute;
  bottom: -300px;
  width: 1700px;
  left: -200px;
  pointer-events: none;

  @media (max-width: 1400px) {
    left: -400px;
  }

  @media (max-width: 550px) {
    display: none;
  }
`;

export const GradientMobile = styled.img`
  display: none;
  @media (max-width: 550px) {
    display: block;
    position: absolute;
    right: -10px;
    top: -80px;
    pointer-events: none;
  }
`;

export const StepArrowWrapper = styled.div`
  display: flex;
`;

export const ArrowWrapper = styled.img`
  width: 40%;
  position: absolute;
  top: 45%;
  left: 100%;

  &.final {
    top: unset;
    bottom: 45%;
  }

  @media (max-width: 1687px) {
    &.second {
      top: 35%;
    }
  }

  @media (max-width: 1600px) {
    width: 17%;

    &.first {
      top: 70%;
    }

    &.second {
      top: 65%;
    }
  }

  @media (max-width: 1440px) {
    width: 17%;

    &.first {
      top: 80%;
    }

    &.second {
      top: 45%;
    }

    &.final {
      bottom: 70%;
    }
  }

  @media (max-width: 1280px) {
    &.first {
      top: 90%;
    }
    &.second {
      top: 65%;
    }

    &.final {
      bottom: 55%;
    }
  }

  @media (max-width: 1226px) {
    &.second {
      top: 55%;
    }
    &.final {
      bottom: 70%;
    }
  }

  @media (max-width: 1142px) {
    &.final {
      bottom: 60%;
    }
  }

  @media (max-width: 1024px) {
    display: none !important;
  }
`;

export const StakingFooterWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0;
  border: 1px solid #3e3e3e;
  border-left: none;
  border-right: none;

  @media (max-width: 1024px) {
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 50px;
    border-left: none;
    border-right: none;
  }

  @media (max-width: 550px) {
    margin-top: 40px;
  }
`;

export const StakingFooterItem = styled.div`
  display: flex;
  width: 100%;
  padding: 10px 0;

  @media (max-width: 1024px) {
    padding: 34px;
  }
`;

export const StakingFooterNumber = styled.div`
  text-align: center;
  width: 70%;
  font-weight: 500;
  font-size: 44px;

  @media (max-width: 1100px) {
    font-size: 24px;
  }

  @media (max-width: 1024px) {
    font-size: 22px;
    width: 50%;
    text-align: right;
  }
`;

export const StakingFooterLabel = styled.div`
  width: 30%;
  font-size: 16px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;

  @media (max-width: 1024px) {
    justify-content: left;
    width: 50%;
    font-weight: 400;
  }

  @media (max-width: 550px) {
    font-size: 12px;
  }
`;

export const CustomedDivider = styled(Divider)`
  border: 1px solid #3e3e3e;
  border-right: none;
  border-bottom: none;
  height: 100%;

  @media (max-width: 1024px) {
    width: 100%;
    margin: 0;

    &.tab-divider {
      width: unset;
      height: unset;
      border: 1px solid #2d2d2d;
      border-right: none;
    }
  }
`;

export const WalletNotConnectedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 100px 0 202px;

  span {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    text-align: center;
    line-height: normal;
    font-family: Neue Haas Grotesk Display Pro;
  }

  svg {
    width: 40px;
    height: 40px;
  }

  @media (max-width: 1024px) {
    span {
      font-size: 16px;
    }
  }
`;

export const TabsContainer = styled.div`
  display: none;
  width: 100%;
  background-color: #15151b;

  @media (max-width: 1024px) {
    display: flex;
  }
`;

export const TabWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border-bottom: 1px solid #2d2d2d;
  color: #4f4f4f;
  height: 56px;
  cursor: pointer;

  &.active-tab {
    color: #00aafd;
    border-bottom: 1px solid #00aafd;
  }
`;

export const StepName = styled.div`
  display: flex;
  column-gap: 19px;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
`;

export const StepNumber = styled.div`
  font-size: 22px;
  width: 46px;
  height: 46px;
  border-radius: 100px;
  background-color: #15151b;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.8), 0 2px 2px rgba(0, 0, 0, 0.6),
    -2px 0 2px rgba(0, 0, 0, 0.6), 2px 0 2px rgba(0, 0, 0, 0.6);
`;
