import { PlatformConfig } from 'components/PlatformConfig';
import { AdminTable } from 'components/Table/AdminTable';
import { AffiliateTable } from 'components/Table/AffiliateTable';
import { GamerDataTable } from 'components/Table/GamerDataTable';
import { GameRevenue } from 'components/Table/GameRevenue';
import { ProviderWhitelistTable } from 'components/Table/ProviderWhitelistTable';
import { SupportedTokenTable } from 'components/Table/SupportedTokenTable';
import { PlayerRoles } from 'constants/enum/PlayerRole';
import { useState } from 'react';
import { useAppSelector } from 'redux/store';
import HomepageTemplate from 'template/Homepage';

import { TesterTable } from 'components/Table/TesterTable';
import {
  AdminPanelWrapper,
  ButtonBlockWrapper,
  ButtonWrapper,
  ContentBlockWrapper,
  SelectTab,
} from './styled';
import { SecurityFlagTable } from 'components/Table/SecurityFlagTable';
import { Select } from 'antd';
import { AdminPanelTabs } from 'constants/enum/AdminPanelTabs';

export const AdminPanel = () => {
  const { Option } = Select;
  const { playerInfo } = useAppSelector(state => state.player);
  const [activeButton, setActiveButton] = useState(
    playerInfo?.roles.includes(PlayerRoles.SuperAdmin)
      ? 'platformConfig'
      : 'gameProvider',
  );
  const [activeContent, setActiveContent] = useState(
    playerInfo?.roles.includes(PlayerRoles.SuperAdmin) ? (
      <PlatformConfig />
    ) : (
      <ProviderWhitelistTable />
    ),
  );

  const handleButtonClick = buttonName => {
    setActiveButton(buttonName);
    let contentComponent;

    switch (buttonName) {
      case AdminPanelTabs.platformConfig:
        contentComponent = <PlatformConfig />;
        break;
      case AdminPanelTabs.admin:
        contentComponent = <AdminTable />;
        break;
      case AdminPanelTabs.gameProvider:
        contentComponent = <ProviderWhitelistTable />;
        break;
      case AdminPanelTabs.affiliate:
        contentComponent = <AffiliateTable />;
        break;
      case AdminPanelTabs.tester:
        contentComponent = <TesterTable />;
        break;
      case AdminPanelTabs.supportedToken:
        contentComponent = <SupportedTokenTable />;
        break;
      case AdminPanelTabs.gamerData:
        contentComponent = <GamerDataTable />;
        break;
      case AdminPanelTabs.gameRevenue:
        contentComponent = <GameRevenue />;
        break;
      case AdminPanelTabs.securityFlag:
        contentComponent = <SecurityFlagTable />;
        break;
      default:
        contentComponent = <PlatformConfig />;
        break;
    }

    setActiveContent(contentComponent);
  };

  return (
    <HomepageTemplate>
      <AdminPanelWrapper>
        <SelectTab value={activeButton} onChange={handleButtonClick}>
          {playerInfo?.roles.includes(PlayerRoles.SuperAdmin) && (
            <>
              <Option
                key={AdminPanelTabs.platformConfig}
                value={AdminPanelTabs.platformConfig}
              >
                Platform config
              </Option>
              <Option key="admin" value="admin">
                Manage Admins
              </Option>
            </>
          )}

          <Option
            key={AdminPanelTabs.gameProvider}
            value={AdminPanelTabs.gameProvider}
          >
            Manage Game Providers
          </Option>

          <Option
            key={AdminPanelTabs.affiliate}
            value={AdminPanelTabs.affiliate}
          >
            Manage Affiliate Users
          </Option>

          <Option key={AdminPanelTabs.tester} value={AdminPanelTabs.tester}>
            Manage Testers
          </Option>

          <Option
            key={AdminPanelTabs.gamerData}
            value={AdminPanelTabs.gamerData}
          >
            Gamer Data
          </Option>

          <Option
            key={AdminPanelTabs.gameRevenue}
            value={AdminPanelTabs.gameRevenue}
          >
            Game Revenue
          </Option>
          <Option
            key={AdminPanelTabs.securityFlag}
            value={AdminPanelTabs.securityFlag}
          >
            Security Flag
          </Option>
        </SelectTab>
        <ButtonBlockWrapper>
          {playerInfo?.roles.includes(PlayerRoles.SuperAdmin) && (
            <>
              <ButtonWrapper
                active={activeButton === AdminPanelTabs.platformConfig}
                onClick={() => handleButtonClick(AdminPanelTabs.platformConfig)}
              >
                Platform config
              </ButtonWrapper>
              <ButtonWrapper
                active={activeButton === AdminPanelTabs.admin}
                onClick={() => handleButtonClick(AdminPanelTabs.admin)}
              >
                Manage Admins
              </ButtonWrapper>
            </>
          )}

          <ButtonWrapper
            active={activeButton === AdminPanelTabs.gameProvider}
            onClick={() => handleButtonClick(AdminPanelTabs.gameProvider)}
          >
            Manage Game Providers
          </ButtonWrapper>

          <ButtonWrapper
            active={activeButton === AdminPanelTabs.affiliate}
            onClick={() => handleButtonClick(AdminPanelTabs.affiliate)}
          >
            Manage Affiliate Users
          </ButtonWrapper>

          <ButtonWrapper
            active={activeButton === AdminPanelTabs.tester}
            onClick={() => handleButtonClick(AdminPanelTabs.tester)}
          >
            Manage Testers
          </ButtonWrapper>

          <ButtonWrapper
            active={activeButton === AdminPanelTabs.gamerData}
            onClick={() => handleButtonClick(AdminPanelTabs.gamerData)}
          >
            Gamer Data
          </ButtonWrapper>

          <ButtonWrapper
            active={activeButton === AdminPanelTabs.gameRevenue}
            onClick={() => handleButtonClick(AdminPanelTabs.gameRevenue)}
          >
            Game Revenue
          </ButtonWrapper>
          <ButtonWrapper
            active={activeButton === AdminPanelTabs.securityFlag}
            onClick={() => handleButtonClick(AdminPanelTabs.securityFlag)}
          >
            Security Flag
          </ButtonWrapper>
          {/* 
          {playerInfo?.roles.includes(PlayerRoles.SuperAdmin) && (
            <>
              <ButtonWrapper
                active={activeButton === 'supportedToken'}
                onClick={() => handleButtonClick('supportedToken')}
              >
                Supported tokens
              </ButtonWrapper>
            </>
          )} */}
        </ButtonBlockWrapper>

        <ContentBlockWrapper>{activeContent}</ContentBlockWrapper>
      </AdminPanelWrapper>
    </HomepageTemplate>
  );
};
