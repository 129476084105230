import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import arrowDownIcon from 'assets/vectors/logo-cashier-arrowdown.svg';
import { SelectToken } from 'components/Modals/Cashier';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { useGetSupportedToken } from 'hooks/cashier/useGetSupportedToken';
import { useGetTokenInfo } from 'hooks/erc20/useGetTokenInfo';
import { useGetRemainingBalance } from 'hooks/token-balance/useGetRemainingBalance';
// import { useDisconnect } from 'hooks/useDisconnect';
import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useDebounce } from 'react-use';
import { ApplicationModal } from 'redux/slices/application';
import { useToggleModal } from 'redux/slices/application/hook';
import { setSignar } from 'redux/slices/signar';
import { loginWallet } from 'redux/slices/user';
import { useAppSelector } from 'redux/store';
import { convertSupportedToPayment } from 'services/api';
import { LocalhostStorage } from 'utils/sessionStorage';
import { injected, WalletConnectInstance } from 'utils/wallets/connector';

import { useAccount, useDisconnect } from 'wagmi';
import { ConfirmWithdraw } from '../ConfirmWithdraw';
import { TokenSelected } from '../TokenSelected';
import { WalletNotConnected } from '../WalletNotConnected';
import {
  CashierRightContentFourthBlock,
  CashierRightContentThirdBlock,
  FourthBlockBalanceUnit,
  FourthBlockBalanceUnitText,
  FourthBlockBalanceWrapper,
  FourthBlockButton,
  FourthBlockButtonWrapper,
  FourthBlockContentWrapper,
  FourthBlockCreditAll,
  FourthBlockCreditTitle,
  FourthBlockCreditValue,
  FourthBlockCreditValueWrapper,
  FourthBlockCreditWrapper,
  ThirdBlockErrorText,
  ThirdBlockTitle,
  ThirdBlockUnit,
  ThirdBlockValue,
} from './styled';

export const WithDraw = ({ setDisabled }) => {
  const [withdrawValue, setWithdrawValue] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [convertedAmount, setConvertedAmount] = useState('0');
  const [convertedInputAmount, setConvertedInputAmount] = useState('0');
  const [debouncedInputAmount, setDebouncedInputAmount] = useState('0');
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);

  const inputRef = useRef(null);

  const toggleSelectTokenModal = useToggleModal(
    ApplicationModal.CASHIER_SELECT_TOKEN,
  );
  const showSelectTokenModal = () => {
    toggleSelectTokenModal();
  };

  const [showConfirm, setShowConfirm] = useState(false);

  const handleNextClick = () => {
    const parsedWithdrawValue = parseFloat(withdrawValue);
    const remainingBalanceAmount = parseFloat(
      formatUnits(
        remainingBalance?.amount || 0,
        remainingBalance?.tokenInfo?.decimals || 18,
      ),
    );

    if (
      parsedWithdrawValue !== null &&
      parsedWithdrawValue > 0 &&
      selectedItem !== null &&
      parsedWithdrawValue <= remainingBalanceAmount &&
      tokenInfo?.tokenAddress === selectedItem?.address &&
      withdrawValue?.toString() === convertedInputAmount &&
      !isInsufficientBalance
    ) {
      setShowConfirm(true);
    }
  };

  const handleBackClick = () => {
    setShowConfirm(false);
    setWithdrawValue(null);
  };

  const preventMinus = e => {
    if (e.code === 'Minus') {
      e.preventDefault();
    }
  };

  const { supportToken } = useGetSupportedToken(
    process.env.REACT_APP_DEFAULT_TOKEN_ADDRESS,
  );

  useDebounce(
    () => {
      void (async () => {
        setDebouncedInputAmount(withdrawValue);
      })();
    },
    500,
    [withdrawValue],
  );

  useEffect(() => {
    const convertAmount = async () => {
      setConvertedAmount('0');
      setConvertedInputAmount('0');
      if (debouncedInputAmount) {
        if (selectedItem) {
          const inputAmount = debouncedInputAmount.toString();
          setLoading(true);

          const config = {
            supportedTokenAddress: supportToken?.address,
            paymentTokenAddress: selectedItem.address,
            amount: parseUnits(
              debouncedInputAmount.toString(),
              supportToken?.decimals || 18,
            ).toString(),
          };

          const res = await convertSupportedToPayment(config);

          setLoading(false);

          if (res?.success) {
            setConvertedAmount(res?.payload?.data?.convertedAmount ?? '0');
            setConvertedInputAmount(inputAmount);
          } else {
            toast.error(res?.message || 'Something went wrong');
          }
        }
      }
    };

    convertAmount();
  }, [selectedItem, debouncedInputAmount, supportToken]);

  const handleValueChange = async number => {
    if (number >= 0 && number < 1e6) {
      const decimalIndex = number.indexOf('.');
      let sanitizedValue = number;

      if (decimalIndex !== -1 && number.length - decimalIndex > 3) {
        sanitizedValue = number.slice(0, decimalIndex + 3);
      }

      setWithdrawValue(sanitizedValue);
    }
  };

  const { refreshToken } = useAppSelector(state => state.refreshToken);

  const { remainingBalance } = useGetRemainingBalance(
    process.env.REACT_APP_DEFAULT_TOKEN_ADDRESS,
    refreshToken,
  );

  const handleAllClick = () => {
    const creditValue = formatUnits(
      remainingBalance?.amount || 0,
      remainingBalance?.tokenInfo?.decimals || 18,
    );
    setWithdrawValue(
      creditValue.slice(0, creditValue.indexOf('.') + 3).toString(),
    );
  };

  const { activate } = useWeb3React<Web3Provider>();

  const CHAIN_ID = LocalhostStorage.get('chainId');
  const dispatch = useDispatch();
  // const disconnect = useDisconnect();
  const { address: account } = useAccount();
  const { disconnect } = useDisconnect();

  useEffect(() => {
    void (() => {
      const isConnected = Boolean(LocalhostStorage.get('isConnected'));
      const typeOfConnector = LocalhostStorage.get('typeOfConnector');

      if (isConnected && typeOfConnector === 'Metamask') {
        activate(injected(CHAIN_ID));
        dispatch(loginWallet(LocalhostStorage.get('account')));
      }

      if (isConnected && typeOfConnector === 'Walletconnect') {
        activate(WalletConnectInstance);
        dispatch(loginWallet(LocalhostStorage.get('account')));
      }
    })();
  }, [CHAIN_ID]);

  useEffect(() => {
    const isConnected = Boolean(LocalhostStorage.get('isConnected'));
    const walletAddress = LocalhostStorage.get('account');
    dispatch(setSignar(LocalhostStorage.get('isConnected')));

    if (!isEmpty(account)) {
      if (
        isConnected &&
        account?.toUpperCase() !== walletAddress?.toUpperCase()
      ) {
        disconnect();
      }
    }
    if (!isConnected) {
      // navigate(PitRouter.CONNECT);
      // disconnect();
    }
  }, [account]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, withdrawValue]);

  const { tokenInfo, loading: tokenLoading } = useGetTokenInfo(
    selectedItem?.address,
    refresh,
  );

  const isInsufficientBalance =
    Number(withdrawValue) >
    Number(
      formatUnits(
        remainingBalance?.amount || 0,
        remainingBalance?.tokenInfo?.decimals || 18,
      ),
    );

  return (
    <>
      {account ? (
        <>
          {showConfirm ? (
            <ConfirmWithdraw
              onBack={handleBackClick}
              value={withdrawValue}
              confirmData={selectedItem}
              convertData={convertedAmount}
              setDisabled={setDisabled}
            />
          ) : (
            <>
              <CashierRightContentThirdBlock>
                <ThirdBlockTitle>Enter withdraw amount</ThirdBlockTitle>
                <ThirdBlockValue
                  min="0"
                  type="number"
                  placeholder="0"
                  bordered={false}
                  value={withdrawValue}
                  onKeyPress={preventMinus}
                  onChange={e => handleValueChange(e.target.value)}
                  // addonAfter={withdrawValue ? 'BIGA' : ''}
                  ref={inputRef}
                />
                <ThirdBlockUnit>
                  {selectedItem &&
                    formatUnits(
                      withdrawValue ? convertedAmount : '0',
                      selectedItem?.decimals || 18,
                    ) +
                      ' ' +
                      selectedItem?.symbol}
                </ThirdBlockUnit>
                {isInsufficientBalance && (
                  <ThirdBlockErrorText>
                    Insufficient balance
                  </ThirdBlockErrorText>
                )}
              </CashierRightContentThirdBlock>

              <CashierRightContentFourthBlock>
                <FourthBlockContentWrapper withdraw>
                  <FourthBlockCreditWrapper>
                    <FourthBlockCreditTitle>
                      Available credits:{' '}
                      <FourthBlockCreditValue>
                        {remainingBalance?.amount
                          ? Number(
                              formatUnits(
                                remainingBalance?.amount || 0,
                                remainingBalance?.tokenInfo?.decimals || 18,
                              ),
                            ).toFixed(2)
                          : 0}{' '}
                        BIGA
                      </FourthBlockCreditValue>
                    </FourthBlockCreditTitle>
                    <FourthBlockCreditValueWrapper>
                      <FourthBlockCreditAll onClick={handleAllClick}>
                        Withdraw All
                      </FourthBlockCreditAll>
                    </FourthBlockCreditValueWrapper>
                  </FourthBlockCreditWrapper>

                  <FourthBlockBalanceWrapper>
                    <TokenSelected item={selectedItem} />
                    <FourthBlockBalanceUnit onClick={showSelectTokenModal}>
                      <FourthBlockBalanceUnitText>
                        {selectedItem?.name || 'No Token'}
                      </FourthBlockBalanceUnitText>
                      <img src={arrowDownIcon} alt="Arrow Down Icon" />
                    </FourthBlockBalanceUnit>
                  </FourthBlockBalanceWrapper>
                </FourthBlockContentWrapper>

                <FourthBlockButtonWrapper>
                  <FourthBlockButton
                    onClick={handleNextClick}
                    disabled={
                      !withdrawValue ||
                      Number(withdrawValue) <= 0 ||
                      !selectedItem ||
                      Number(withdrawValue) >
                        Number(
                          formatUnits(
                            remainingBalance?.amount || 0,
                            remainingBalance?.tokenInfo?.decimals || 18,
                          ),
                        ) ||
                      tokenInfo?.tokenAddress !== selectedItem?.address ||
                      withdrawValue?.toString() !== convertedInputAmount ||
                      isInsufficientBalance
                    }
                  >
                    Next
                  </FourthBlockButton>
                </FourthBlockButtonWrapper>
                <SelectToken
                  onItemSelected={value => {
                    setSelectedItem(value);
                    setConvertedAmount('0');
                  }}
                />
              </CashierRightContentFourthBlock>
            </>
          )}
        </>
      ) : (
        <WalletNotConnected />
      )}
    </>
  );
};
