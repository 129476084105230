import { useEffect, useState } from 'react';
import { checkConnectedWallet } from 'services/api';

export const useCheckConnectedWallet = (
  params: { walletAddress?: string },
  refresh?: any,
) => {
  const [isConnected, setIsConnected] = useState<boolean>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      if (!params.walletAddress) {
        return;
      }
      setLoading(true);

      const res = await checkConnectedWallet(params);
      setIsConnected(res?.payload?.data?.isConnected);
      setLoading(false);
    })();
  }, [params, refresh]);

  return { isConnected, loading, setIsConnected };
};
