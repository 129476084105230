import { PitRouter } from 'constants/routers';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutUser } from 'redux/slices/player';
import { useAppSelector } from 'redux/store';
import { logout } from 'services/api';
import { LocalhostStorage } from 'utils/sessionStorage';
import useWagmiConnectors from './useWagmiConnectors';

export const useLogOut = ({
  onRefresh,
  refresh,
}: {
  onRefresh?: () => void;
  refresh?: boolean;
} = {}) => {
  const { onDisconnect } = useWagmiConnectors();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logOut = async () => {
    onDisconnect();
    await logout(LocalhostStorage.get('refreshToken'));
    LocalhostStorage.remove('accessToken');
    LocalhostStorage.remove('refreshToken');
    LocalhostStorage.remove('tokenType');
    dispatch(logoutUser());
    if (onRefresh) {
      onRefresh();
    }
    navigate(PitRouter.LOGIN);
  };
  return useCallback(logOut, [onDisconnect, navigate, onRefresh]);
};
