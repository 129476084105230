import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import store, { persistor } from 'redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { AppRouter } from 'routes';
import { WagmiProvider } from 'wagmi';
import { config } from './config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import 'antd/dist/antd.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/config.css';
import './styles/dropdown.css';

function App(): JSX.Element {
  const helmetContext = {};
  const queryClient = new QueryClient();
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider context={helmetContext}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <AppRouter />
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="dark"
              />
            </PersistGate>
          </Provider>
        </HelmetProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
