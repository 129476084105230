import { BigNumber } from 'ethers';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { predefined } from 'services/api';

import { useStoshiContract } from './useStoshiContract';
import { useAccount } from 'wagmi';

export const useStoshiCallback = () => {
  const { address: account } = useAccount();
  const gameContract = useStoshiContract();

  const startLevel = useCallback(
    async (
      gameId,
      levelIds,
      playTimesPerLevel,
      eventTime,
      signature: string,
    ) => {
      try {
        if (gameContract && account) {
          const gasLimit = await gameContract.estimateGas.startLevel(
            gameId,
            levelIds,
            playTimesPerLevel,
            eventTime,
            signature,
          );
          const tx = await gameContract.startLevel(
            gameId,
            levelIds,
            playTimesPerLevel,
            eventTime,
            signature,
            {
              gasLimit: BigNumber.from(gasLimit).mul(2),
            },
          );
          return tx.wait(1);
        }
      } catch (e) {
        console.log(e);
        if (
          e?.reason ===
          'execution reverted: ERC20: transfer amount exceeds balance'
        ) {
          toast.error("You don't have enough balance.");
        } else {
          toast.error(e.reason || e.message);
        }
      }
    },
    [account, gameContract],
  );

  const finishLevel = useCallback(
    async (gameId, levelId, eventTime, signature: string, indexSession) => {
      try {
        if (gameContract && account) {
          const gasLimit = await gameContract.estimateGas.finishLevel(
            gameId,
            levelId,
            eventTime,
            signature,
            indexSession,
          );
          const tx = await gameContract.finishLevel(
            gameId,
            levelId,
            eventTime,
            signature,
            indexSession,
            {
              gasLimit: BigNumber.from(gasLimit).mul(2),
            },
          );
          return tx.wait(1);
        }
      } catch (e) {
        console.log(e.message);
        toast.error(e.reason || e.message);
      }
    },
    [account, gameContract],
  );

  const addInternalGame = useCallback(
    async (config, gameName: string) => {
      try {
        if (gameContract && account) {
          const gasLimit = await gameContract.estimateGas.addInternalGame(
            config,
          );
          const tx = await gameContract.addInternalGame(config, {
            gasLimit: BigNumber.from(gasLimit).mul(2),
          });
          await predefined({
            transactionHash: tx.hash,
            name: gameName,
          });
          return tx.wait(1);
        }
      } catch (e) {
        console.log(e);
        toast.error(e.reason || e.message);
      }
    },
    [account, gameContract],
  );

  const addExternalGame = useCallback(
    async (config, signature: string, gameName: string) => {
      try {
        if (gameContract && account) {
          const gasLimit = await gameContract.estimateGas.addExternalGame(
            config,
            signature,
          );
          const tx = await gameContract.addExternalGame(config, signature, {
            gasLimit: BigNumber.from(gasLimit).mul(2),
          });
          await predefined({
            transactionHash: tx.hash,
            name: gameName,
          });
          return tx.wait(1);
        }
      } catch (e) {
        console.log(e);
        toast.error(e.reason || e.message);
      }
    },
    [account, gameContract],
  );

  const setGameActiveStatus = useCallback(
    async (gameId, isActive: boolean) => {
      try {
        if (gameContract && account) {
          const gasLimit = await gameContract.estimateGas.setGameActiveStatus(
            gameId,
            isActive,
          );
          const tx = await gameContract.setGameActiveStatus(gameId, isActive, {
            gasLimit: BigNumber.from(gasLimit).mul(2),
          });
          return tx.wait(1);
        }
      } catch (e) {
        console.log(e.message);
        toast.error(e.reason || e.message);
      }
    },
    [account, gameContract],
  );

  const deposit: any = useCallback(
    async param => {
      try {
        if (gameContract && account) {
          const gasLimit = await gameContract.estimateGas.deposit(
            param._tokenIn,
            param._tokenOut,
            param._amountIn,
          );
          const tx = await gameContract.deposit(
            param._tokenIn,
            param._tokenOut,
            param._amountIn,
            {
              gasLimit: BigNumber.from(gasLimit).mul(2),
            },
          );
          return tx.wait(1);
        }
      } catch (e) {
        console.log(e.message);
        toast.error(e.reason || e.message);
      }
    },
    [account, gameContract],
  );

  const getGasLimitForDeposit: any = useCallback(
    async param => {
      try {
        if (gameContract && account) {
          const gasLimit = await gameContract.estimateGas.deposit(
            param._tokenIn,
            param._tokenOut,
            param._amountIn,
          );

          return gasLimit;
        }
      } catch (e) {
        console.log(e.message);
        toast.error(e.reason || e.message);
      }
    },
    [account, gameContract],
  );

  const withdraw = useCallback(
    async (_tokenIn, _tokenOut, _amountOut, _nonce, _signature) => {
      try {
        if (gameContract && account) {
          const gasLimit = await gameContract.estimateGas.withdraw(
            _tokenIn,
            _tokenOut,
            _amountOut,
            _nonce,
            _signature,
          );
          const tx = await gameContract.withdraw(
            _tokenIn,
            _tokenOut,
            _amountOut,
            _nonce,
            _signature,
            {
              gasLimit: BigNumber.from(gasLimit).mul(2),
            },
          );
          return tx.wait(1);
        }
      } catch (e) {
        console.log(e.message);
        toast.error(e.reason || e.message);
      }
    },
    [account, gameContract],
  );

  const getGasLimitForWithdraw = useCallback(
    async (_tokenIn, _tokenOut, _amountOut, _nonce, _signature) => {
      try {
        if (gameContract && account) {
          const gasLimit = await gameContract.estimateGas.owner();
          return gasLimit;
        }
      } catch (e) {
        console.log(e.message);
        toast.error(e.reason || e.message);
      }
    },
    [account, gameContract],
  );

  // Read function

  const levels = useCallback(
    async level => {
      try {
        if (gameContract && account) {
          const tx = await gameContract.levels(level);
          return tx;
        }
      } catch (e) {
        console.log(e.message);
      }
    },
    [account, gameContract],
  );

  const owner = useCallback(async () => {
    try {
      if (gameContract && account) {
        const tx = await gameContract.owner();
        return tx;
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [account, gameContract]);

  const getLevel = useCallback(
    async (gameId, levelId) => {
      try {
        if (gameContract && account) {
          const tx = await gameContract.getLevel(gameId, levelId);
          return tx;
        }
      } catch (e) {
        console.log(e.message);
      }
    },
    [account, gameContract],
  );

  const getPlatformConfig = useCallback(async () => {
    try {
      if (gameContract && account) {
        const tx = await gameContract.getPlatformConfig();
        return tx;
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [account, gameContract]);

  const isGameProvider = useCallback(async () => {
    try {
      if (gameContract && account) {
        const tx = await gameContract.isGameProvider(account);
        return tx;
      }
    } catch (e) {
      console.log(e.message);
    }
  }, [account, gameContract]);

  return {
    // Write function
    addExternalGame,
    addInternalGame,
    finishLevel,
    startLevel,
    setGameActiveStatus,
    deposit,
    getGasLimitForDeposit,
    withdraw,
    getGasLimitForWithdraw,
    // Read function
    getPlatformConfig,
    getLevel,
    levels,
    owner,
    isGameProvider,
  };
};
