import { Select } from 'antd';
import { media } from 'constants/enum/breakpoints';
import styled from 'styled-components';

export const AdminPanelContainer = styled.div`
  text-align: center;
  max-width: 900px;
  margin: auto;
  margin-top: 5rem;

  .ant-tabs-nav:before {
    border-bottom: none;
  }

  .ant-tabs-card > .ant-tabs-nav {
    border-top: #fff 1px solid;
    padding-top: 15px;
    padding-bottom: 25px;
    width: 900px;
    margin: 0 auto;
    .ant-tabs-tab {
      border-radius: 6.5px;
      background-color: rgb(255 255 255 / 46%);
      .ant-tabs-tab-btn {
        color: #0b3885;
        font-size: 17px;
        font-weight: 600;
        line-height: 20px;
      }
      &.ant-tabs-tab-active {
        background-color: #fff;
      }
    }
  }
`;

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const AdminPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  ${media.xl1} {
    margin-left: 20px;
  }

  ${media.md} {
    margin-left: 0;
  }

  ${media.sm} {
    gap: 30px;
  }
`;

export const ButtonBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.08);

  @media (max-width: 1200px) {
    flex-direction: column;
    display: none;
  }
`;

export const ButtonWrapper = styled(Text)<{ active?: boolean }>`
  padding: 16px 0;
  flex: 1;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-bottom: 1px solid
    ${props => (props?.active ? 'rgba(0, 170, 253, 1)' : 'none')};
  color: ${props => (props?.active ? 'rgba(0, 170, 253, 1)' : '#fff')};

  cursor: pointer;
  user-select: none;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;

  &:hover {
    color: rgba(0, 170, 253, 1);
  }

  @media (max-width: 1200px) {
    align-self: stretch;
  }
`;

export const ContentBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const FirstBlockContentWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const FirstBlockItemWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const SelectTab = styled(Select)`
  border-radius: 12px;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  height: 48px;
  text-align: center;
  display: none;
  margin: 10px 10px 0;

  svg {
    fill: #fff;
  }

  .ant-select-selector {
    .ant-select-selection-item {
      padding-right: 0;
      font-weight: 600;
      font-size: 17px;
    }
  }

  @media (max-width: 1200px) {
    display: inline-block;
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    align-items: center;
    height: 48px;
    border-radius: 6.5px;
    border: none;
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 36px 0 13px;
  }

  &.ant-select-dropdown.ant-select-dropdown-empty {
    border-radius: 12px;
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    box-shadow: none;
  }
`;
