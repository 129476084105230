import { List, Select } from 'antd';
import { TokenItem } from 'components/Cashier/TokenItem';
import { useGetListPaymentToken } from 'hooks/cashier/useGetListPaymentToken';
import { useMemo, useState } from 'react';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';
import { LocalhostStorage } from 'utils/sessionStorage';
import {
  networkTypeSelected,
  SUPPORTED_NETWORKS,
} from 'utils/wallets/connector';

import {
  ModalSelectTokenContainer,
  ModalSelectTokenFirstBlock,
  ModalSelectTokenSecondBlock,
  ModalSelectTokenWrapper,
  ModalWrapper,
  NetworkImage,
  SearchOutlinedStyled,
  SelectNetwork,
} from './styled';
import useWagmiConnectors from 'hooks/useWagmiConnectors';
import { useAccount, useSwitchChain } from 'wagmi';

const { Option } = Select;

export const SelectToken = ({ onItemSelected }) => {
  const open = useModalIsOpen(ApplicationModal.CASHIER_SELECT_TOKEN);
  const toggleModal = useToggleModal(ApplicationModal.CASHIER_SELECT_TOKEN);
  const handleCancel = () => {
    toggleModal();
  };

  const handleItemSelected = item => {
    onItemSelected(item);
    handleCancel();
  };
  const { switchChain } = useSwitchChain();
  const { chainId } = useAccount();

  const networkType = useMemo(() => {
    if (!chainId) return;
    const network = Object.values(SUPPORTED_NETWORKS).find(
      network => network.chainId === chainId,
    );
    if (network) {
      return network.netType;
    } else {
      return;
    }
  }, [chainId]);

  const deposits = Object.values(SUPPORTED_NETWORKS).filter(
    network => network.netType === networkType,
  );
  const defaultChainId = process.env.REACT_APP_CHAIN_ID;
  const defaultDepositName =
    deposits.find(dep => dep.chainId === LocalhostStorage.get('chainId'))
      ?.name ?? '';
  const defaultDepositChainId =
    deposits.find(dep => dep.chainId === LocalhostStorage.get('chainId'))
      ?.chainId ?? +defaultChainId;
  const [searchQuery, setSearchQuery] = useState('');
  const [params, setParams] = useState({
    pageSize: 20,
    pageIndex: 1,
    chainId: defaultDepositChainId,
  });
  const { onConnectWallet } = useWagmiConnectors();

  const [selectedDeposit, setSelectedDeposit] = useState(defaultDepositName);

  const {
    listPaymentToken,
    loading: listPaymentTokenLoading,
    total: listPaymentTokenTotal,
  } = useGetListPaymentToken(params);

  const handleSelect = deposit => {
    try {
      if (!deposit) return;
      const getSelectedChainId = deposits.find(
        dep => dep.name === deposit,
      ).chainId;
      LocalhostStorage.set('chainId', getSelectedChainId);
      switchChain(
        { chainId: getSelectedChainId },
        {
          onError: error => {
            console.log(error.message);
          },
        },
      );
      setSelectedDeposit(deposit);
      setParams(prev => ({
        ...prev,
        chainId: getSelectedChainId,
      }));
    } catch (err) {
      console.log('Change network error ', err);
    }
  };

  return (
    <ModalWrapper
      open={open}
      onCancel={handleCancel}
      maskClosable={true}
      footer={null}
      width="528px"
    >
      <ModalSelectTokenWrapper>
        <ModalSelectTokenContainer>
          <ModalSelectTokenFirstBlock>
            Select a cryptocurrency
          </ModalSelectTokenFirstBlock>

          <ModalSelectTokenSecondBlock
            placeholder="Search for a cryptocurrency"
            allowClear
            addonBefore={<SearchOutlinedStyled />}
            onChange={e => setSearchQuery(e.target.value)}
          />
          <SelectNetwork
            value={selectedDeposit}
            onChange={e => {
              handleSelect(e);
            }}
            style={{ width: '12rem' }}
          >
            {deposits?.map(deposit => (
              <Option key={deposit.chainId} value={deposit.name}>
                <NetworkImage
                  style={{ width: '1rem', height: '1rem' }}
                  src={deposit?.iconUrl}
                />
                {deposit.name}
              </Option>
            ))}
          </SelectNetwork>
          <List
            dataSource={
              listPaymentToken
                ? listPaymentToken.filter(
                    item =>
                      item.name
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      item.symbol
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase()),
                  )
                : []
            }
            renderItem={item => (
              <TokenItem item={item} handleItemSelected={handleItemSelected} />
            )}
          />
        </ModalSelectTokenContainer>
      </ModalSelectTokenWrapper>
    </ModalWrapper>
  );
};
